/** Core **/
@import "normalize.css";
@import "global.css";
@import "animations.css";

/* Angular Material */
@import "indigo-pink.css";
@import "palette.css";

/* Bootstrap Grid */
@import "bootstrap.css";

/* Date-Time Picker*/
/*@import '~ng-pick-datetime-ex/assets/style/picker.min.css';*/