/*@font-face {
    font-family: 'Open Sans';
    src: url('/assets/fonts/OpenSans-VariableFont_wdthwght.woff') format('woff');
}
body {
    font-family: Open Sans;
    font-weight: normal;
    font-style: normal;
}*/

body {
    font-family: "Inter", sans-serif;
}

html {
    scroll-behavior: smooth;
}

/* TEXT COLORS */

.--txt-blue {
    color: var(--primary) !important;
}

.--txt-white {
    color: #fff !important;
}

.--txt-grey {
    color: var(--grey-dark) !important;
}

.--txt-grey-light {
    color: var(--grey) !important;
}

.--txt-red {
    color: var(--warn) !important;
}

.--txt-red-trans {
    color: var(--warn-trans) !important;
}

.--txt-red-dark {
    color: #911914 !important;
}

.--txt-red-light {
    color: var(--red-light) !important;
}

.--txt-black {
    color: #000 !important;
}

.--txt-pink {
    color: var(--accent) !important;
}

.--txt-blue-light {
    color: var(--indigo-200) !important;
}

.--txt-green {
    color: var(--green) !important;
}

.--txt-green-trans {
    color: var(--green-trans) !important;
}

.--txt-orange {
    color: orange !important;
}

.--txt-orange-trans {
    color: #ffa5008c !important;
}

.--txt-green-light {
    color: var(--green-light) !important;
}

/* BACKGROUND COLORS */
.--bg-blue {
    background-color: var(--primary) !important;
}

.--bg-warn {
    background-color: var(--warn) !important;
}

.--bg-warn-trans {
    background-color: var(--warn-trans) !important;
}

.--bg-blue-lighter {
    background-color: var(--indigo-200) !important;
}

.--bg-blue-light {
    background-color: var(--indigo-100) !important;
}

.--bg-blue-alt {
    background-color: var(--blue-alt) !important;
}

.--bg-white {
    background-color: #fff !important;
}

.--bg-yellow {
    background-color: var(--yellow-500) !important;
}

.--bg-grey-light {
    background-color: var(--grey-light) !important;
}

.--bg-grey {
    background-color: var(--grey) !important;
}

.--bg-grey-dark {
    background-color: var(--grey-dark) !important;
}

.--bg-green-light {
    background-color: var(--green-light) !important;
}

.--bg-green {
    background-color: var(--green) !important;
}

.--bg-green-trans {
    background-color: var(--green-trans) !important;
}

.--bg-orange {
    background-color: orange !important;
}

.--bg-orange-trans {
    background-color: #ffa5008c !important;
}

.--bg-red-light {
    background-color: var(--red-light) !important;
}

.--bg-grey-horiz-separators {
    background-color: var(--grey-light) !important;
    border-top: 1px solid var(--grey);
    border-bottom: 1px solid var(--grey);
}

.--color-grey {
    color: var(--grey-dark) !important;
}

.--row-fix {
    margin: 0 -20px;
}

.text-bold {
    font-weight: 600 !important;
}

/* SHADOWS */

.--box-shadow-none {
    box-shadow: none !important;;
}

.--box-shadow-sm {
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12) !important;
}

.rm-checkbox {
    color: var(--grey-dark);
    font-size: 16px;
}

.rm-radio {
    color: var(--grey-dark);
}

.rm-radio-label {
    display: block;
    margin-bottom: 10px;
    font-size: 13px;
    color: var(--primary);
}


.v-collapse {
    visibility: collapse;
}

/* MAT-TABLE */


.sortable-header {
    cursor: pointer;
}
.sortable-header:hover {
    color: var(--primary);
}

.sortable-header i {
    margin-right: 0.5rem;
}

.flip-icon {
    transform: rotate(180deg);
}

.section-header {
    background-color: var(--primary);
    display: flex !important;
    align-items: center;
    padding: 15px 29px;
    margin: -24px -24px 20px !important;
    border-radius: 3px 3px 0 0;
    color: #fff;
    font-size: 18px !important;
}

.table-header {
    background-color: var(--primary);
    text-align: center;
    margin-bottom: 20px;
}

.mat-header-cell {
    font-size: 14px !important;
}

.mat-column-checkbox {
    max-width: 8%;
}

/* STEPPER */
.ng-mat-stepper-title {
    padding: 15px 25px;
    font-size: 18px !important;
    background-color: var(--primary);
    color: white;
    margin: 30px -10px 20px;
    text-align: left;
    width: calc(100% + 30px);
    display: flex;
    flex-direction: row;
    align-items: center;
}

.step-divider {
    background-color: var(--grey);
    height: 1px;
    width: 10%;
}

/* COMMON CLASSES */

.navbar-margin {
    padding-top: 55px;
}

.w-35 {
    width: 35% !important;
}

.mb-6 {
    margin-bottom: 4rem;
}

.mb-7 {
    margin-bottom: 5rem;
}

@media(max-width: 991px) {
    .long-text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 150px;
        display: block;
        height: 20px;
        transition: .5s ease;
    }
}

@media(min-width: 992px) {
    .long-text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 350px;
        display: block;
        height: 20px;
        transition: .5s ease;
    }
}

.text-decoration-none{
    text-decoration: none;
}

/* MAIN BUTTON */
.uniumx-button {
    width: 210px;
    font-size: 12px !important;
    height: 36px!important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    border-radius: 10px !important;
}

@media (min-width: 992px) {
    .uniumx-button.uniumx-button-large {
        width: 175px !important;
        min-width: 175px !important;
    }
}

.uniumx-button img {
    height: 20px;
    width: 20px;
    margin-right: 3px;
}

.uniumx-button-text {
    font-size: 14px !important;
}

@media (max-width: 992px) {
    .uniumx-button-text {
        display: none;
    }

    .uniumx-button {
        width: 90px;
    }

    .uniumx-button img {
        margin-right: 0;
    }

}



.magenta {
    color: #e6007e
}

.uniumx-button-small {
    height: 36px;
    line-height: 30px !important;
    width: 150px;
    font-size: 12px !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.uniumx-button-blue {
    background-color: var(--indigo-100) !important;
    color: var(--primary) !important;
    text-transform: uppercase;
}

.button-white {
    background-color: #ffffff !important;
    color: var(--primary) !important;
    text-transform: uppercase;
}

.button-white:disabled {
    color: var(--grey-dark) !important;
}

.username-button {
    border-radius: 20px !important;
}
/* MAIN BUTTON END */

.panel-badge {
    background-color: var(--indigo-300);
    height: 27px;
    min-width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    padding: 0 15px;
    font-size: 14px!important;
    letter-spacing: 0.8px;
    border: 1px solid rgba(0,0,0,.03);
    box-shadow: 0 2px 2px rgba(0,0,0,.24), 0 0 2px rgba(0,0,0,.12);
    margin: 13px 0;
}

.panel-badge span {
    font-size: 14px!important;
}

/* POSITIONING */

.ml-20 {
    margin-left: 20px !important;
}

.position-absolute {
    position: absolute !important;
}

.position-sticky {
    position: sticky !important;
}

.position-relative {
    position: relative !important;
}

/* CONTAINERS */

.container {
    min-height: 100vh;
}

.container-mt {
    margin-top: 80px;
}

.component-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
}

/* HEADINGS */
.subheading {
    font-size: 20px !important;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.heading-underline-blue {
    color: #347ce3 !important;
    font-size: 20px !important;
    font-family: "Inter", sans-serif;
    border-bottom: 3px solid #347ce3;
    font-weight: 600 !important;
}

.subheading-underline-blue-light {
    color: var(--accent);
    font-size: 14px !important;
    font-family: "Inter", sans-serif;
    border-bottom: 1px solid var(--accent);
    font-weight: 600;
}

/* BUTTONS */

.prompt-btn {
    height: 30px;
    width: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.prompt-btn i {
    font-size: 22px;
    color: var(--grey-dark);
    transition: .5s ease;
}

.prompt-btn:hover {
    color: var(--black-700);
}

.add_btn {
    text-transform: uppercase;
    font-weight: 600;
}

.add_btn_icon {
    width: 20px;
    margin-right: 10px;
}

.filter-btn {
    background-color: var(--indigo-100);
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary);
}

.filter-btn:hover {
    background-color: var(--indigo-200);
}

/* MATERIAL ICONS */
 /*fallback*/
@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/materialIcons.eot');
    src: url('/assets/fonts/materialIcons.woff2') format('woff2'),
    url('/assets/fonts/materialIcons.ttf') format('truetype'),
    url('/assets/fonts/materialIcons.woff') format('woff');
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
}

/* FOOTER */

.uniumx-footer-container {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.rm-divider {
    border-bottom: 1px solid rgba(0,0,0,.12);
}

/* SCROLLBAR */

/* width */
::-webkit-scrollbar {
    width: 10px;
    padding: 5px 0;
}

/* Track */
/*::-webkit-scrollbar-track {*/
    /*background: var(--grey);*/
/*}*/

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--grey);
}

::-webkit-scrollbar-thumb:active, ::-webkit-scrollbar-thumb:hover {
    background: var(--grey-dark);
}

/* ADD ENTRY BUTTON */
.add-entry-btn {
    position: absolute !important;
    top: 5px;
    left: -60px;
    color: var(--indigo-300) !important;
    border: 1px solid var(--indigo-300) !important;
    line-height: 37px !important;
    align-items: center;
    justify-content: center;
    font-size: 16px !important;
}

.add-entry-btn:hover {
    background-color: var(--indigo-100) !important;
}

.arrow-btn {
    color: var(--indigo-300) !important;
    border: 1px solid var(--indigo-300) !important;
    line-height: 37px !important;
    align-items: center;
    justify-content: center;
    font-size: 16px !important;
}

.arrow-btn:hover {
    background-color: var(--indigo-100) !important;
}

.arrow-btn-reversed > span > img {
    transform: rotate(180deg);
}

.add-in-heading-btn {
    color: var(--indigo-300) !important;
    border: 1px solid var(--indigo-300) !important;
    line-height: 25px !important;
    height: 30px !important;
    width: 30px !important;
    font-size: 23px;
}

.add-in-heading-btn:hover {
    background-color: var(--indigo-100) !important;
}

/* BADGES */
.rm-badge {
    position: absolute;
    top: -10px;
    left: -11px;
    background-color: var(--indigo-200);
    color: #ffffff;
    padding: 6px;
    border-radius: 25px;
    display: block;
    line-height: 1;
    font-size: 12px !important;
    min-width: 25px;
    text-align: center;
}

.rm-badge-sm-right {
    left: unset;
    right: -15px;
    padding: 3px;
    min-width: 19px;
}

.rm-badge-inline {
    position: relative;
    top: -2px;
    left: 0;
    right: unset;
    padding: 6px 10px;
    font-size: 14px;
    background-color: var(--indigo-200);
}

.rm-badge-white {
    background: #ffffff;
    color: var(--primary);
    font-weight: 600;
}

.statistics-heading {
    text-transform: uppercase;
    border-right: 1px solid #ffffff;
    padding-right: 20px;
    line-height: 1.3;
    vertical-align: middle;
}

/* GRADIENT */
.placeholder-gradient {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #fafafa 8%, #efefef 38%, #fafafa 54%);
    background-size: 1000px 640px;

    position: relative;

}

.placeholder-table-item {
    display: flex;
    width: 100%;
    height: 48px;
    align-items: center;
    background-color: #ffffff;
    padding: 0 20px;
    margin: 5px 0;
    border-radius: 2px;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0), 0 2px 2px 0 rgba(0, 0, 0, 0.03), 0 1px 5px 0 rgba(0,0,0,.12);
}

.placeholder-table-text {
    display: block;
    height: 25px;
    background-color: var(--grey);
    margin: 5px 0;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

/* DATE TIME PICKER */
.owl-dt-calendar-control,
.owl-dt-calendar-main,
.owl-dt-timer,
.owl-dt-container-buttons {
    font-family: Roboto,"Helvetica Neue",sans-serif;
}

.date-time-picker-input {
    width: 100%;
    border: none;
    outline: none;
    border-bottom: 1px solid #949494;
    font-size: 15px;
    transition: .5s ease;
}
.date-time-picker-placeholder {
    font-size: 13px;
    margin-bottom: 5px;
    display: block;
    color: var(--primary);
}

.date-time-picker-input:focus {
    border-bottom: 2px solid var(--primary);
}

.date-time-picker-input-invalid {
    border-bottom: 1px solid var(--warn);
}

.reloadButtonManualCheck {
    position: absolute;
    left: 33px;
    z-index: 999;
}

.reloadButtonManualCheck button:hover {
    cursor:pointer;
}

/* ICONS */
.svg-24 {
    width: 24px;
    height: 24px;
}

.svg-20 {
    width: 20px;
    height: 20px;
}

.cursor-pointer {
    cursor: pointer;
}

.rm-field-label {
    font-family: Roboto,"Helvetica Neue",sans-serif;
    color: var(--black-700);
    font-size: 10;
}

.menu-right .mat-button-wrapper img {
    width: 24px;
    height: 24px;
}

.mat-drawer-content {
    position: relative;
    z-index: 1;
    display: block;
    height: 100%;
    overflow: auto;
}

container.navbar-margin.mb-6.--media-fix.d-flex.flex-column{
    position:relative;
}

@keyframes placeHolderShimmer {
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}

@media(min-width: 1170px) and (max-width: 1500px) {
    .--media-fix {
        width: 1100px !important;
    }
}

.overflow-hidden {
    overflow: hidden !important;
}

.opacity-100 {
    opacity: 1 !important;
}

.opacity-50 {
    opacity: 0.5 !important;
}

.icon{
    width: 24px !important;
}

img {
    width: 24px;
}

.mat-card-lg-image {
    width: 152px !important;
}

.titleSend {
    width: fit-content;
    padding-right: 10px;
    margin-top: -4px;
}
.titleSend p {
    font-family: Roboto,"Helvetica Neue",sans-serif;
}

.checkBoxes .mat-checkbox-layout .mat-checkbox-label {
    font-size:15px;
}

.specialMarginTop label {
    margin-top: 18px;
    margin-bottom:23px
}

.KeywordMassAction .uniumx-button.uniumx-button-large {
    width: 175px !important;
    min-width: 175px !important;
}

.KeywordMassAction .uniumx-button.uniumx-button-large img{
    margin-right: 3px;
}

.specialMarginTop.mat-checkbox-checked label {
    margin-bottom: 18px
}

.errorLostReason {
    margin-top: 5px;
    text-align: right;
    color: red;
    font-family: Roboto,"Helvetica Neue",sans-serif;
}

.alertUpload .ng-star-inserted {
    background-color: white
}

.alertUpload .mat-raised-button {
    box-shadow: 0px 0px 3px 2px #e7e7e7 !important;
}

.alertUpload .mat-raised-button.level2-button .uniumx-button-text {
    font-weight:bold;
    text-transform:uppercase
}

.textRed {
    color: red !important;
}

.customDialog {
    position: relative;
    background: white;
    height: 59px;
    width: 215px;
    padding: 10px 15px;
    top: -60px;
    right: 195px;
    border: 1px solid #e7e7e7;
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
}

.customDialog button {
    background-color: #2f4057;
    color: white;
}

.customDialog .customDialogExit {
    background-color: white;
    border: 0px solid;
    box-shadow: 0px 0px 2px 2px #c5c5c5;
    font-weight: bold;
    position: absolute;
    top: -5px;
    right: -10px;
    width: 20px;
    height: 20px;
    color: #5f5f5f;
}

.customDialog .customDialogExit:hover {
    cursor:pointer;
}

.customErrorMessage {
    color: #f44336;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.125;
    font-family: Roboto,"Helvetica Neue",sans-serif;
}

.mat-menu-panel {
    min-width: 165px;
}

.mat-mdc-tooltip {
    font-size: 14px !important;
    color: white !important;
}

.level2-button, .alertUpload .mat-raised-button.level2-button {
    background-color: white !important;
    color: #2f4057 !important;
    font-weight: bold !important;
    box-shadow: 0px 0px 3px 2px #e7e7e7 !important;
    font-size: 14px !important;
    margin-bottom: 5px !important;
    text-transform: uppercase;
}

.level2-button:hover, .alertUpload .mat-raised-button.level2-button:hover {
    background-color: white !important;
    color: #2f4057 !important;
    box-shadow: 0px 0px 3px 2px #2f4057 !important;
    cursor: pointer !important;
}

.level2-button[disabled] {
    background-color: rgba(0,0,0,.12);
}

.container .specialIcon {
    margin: 0px 0px 5px 0px !important;
    display: block;
    box-shadow: 0px 0px 0px 0px !important;
    padding-top: 20px;
    padding-left: 5px;
}

.specialIcon img{
    width:30px;
}


.menu-left .mat-icon-button, .menu-right .mat-icon-button {
    background-color: white !important;
    color: #2f4057 !important;
    font-weight: bold !important;
    box-shadow: 0px 0px 3px 2px #e7e7e7 !important;
    font-size: 14px !important;
    margin-bottom: 5px !important;
    width: 40px !important;
    height: 40px !important;
}

.menu-left .mat-icon-button:hover, .menu-right .mat-icon-button:hover {
    background-color: white !important;
    color: #2f4057 !important;
    box-shadow: 0px 0px 3px 2px #2f4057 !important;
    cursor: pointer !important;
}

.loading-spinner {
    margin: 80px 0;
}

/*Same rules for upload buttons*/

.level2-button .upload-btn-default{
    background-color: white !important;
    color: #2f4057 !important;
    font-weight: bold !important;
    box-shadow: 0px 0px 3px 2px #e7e7e7 !important;
    font-size: 13px !important;
    margin-bottom: 5px !important;    
}

.level2-button .upload-btn-default:hover {
    background-color: white !important;
    color: #2f4057 !important;
    box-shadow: none !important;
}

.level2-button .mat-button-focus-overlay, .level2-button .mat-button-focus-overlay {
    background-color: transparent!important;
}

.submit_btn.mat-raised-button.fixedSaveButton {
    position: fixed;
    z-index: 999999;
    bottom: 60px;
    right: 103px;
    width: 75px;
    height: 70px;
    border-radius: 50%;
    padding: 0px;
    margin: 0px;
    min-width: 5px;
}

.roundButton {
    min-width: 0px !important;
    width: 40px !important;
    height: 40px !important;
    padding: 0px !important;
    border-radius: 50% !important;
}

.submit_btn.mat-raised-button.fixedSaveButton span{
    padding:0px;
}

.submit_btn.mat-raised-button.fixedSaveButton img {
    width:30px;
    height:30px;
    margin-right:0px;
}

.mat-button.mat-accent[disabled], .mat-button.mat-primary[disabled], .mat-button.mat-warn[disabled], .mat-button[disabled][disabled], .mat-icon-button.mat-accent[disabled], .mat-icon-button.mat-primary[disabled], .mat-icon-button.mat-warn[disabled], .mat-icon-button[disabled][disabled], .mat-stroked-button.mat-accent[disabled], .mat-stroked-button.mat-primary[disabled], .mat-stroked-button.mat-warn[disabled], .mat-stroked-button[disabled][disabled] {
    color: rgba(0,0,0,.26) !important;
}

.mat-button.mat-accent[disabled]:hover, .mat-button.mat-primary[disabled]:hover, .mat-button.mat-warn[disabled]:hover, .mat-button[disabled]:hover[disabled]:hover, .mat-icon-button.mat-accent[disabled]:hover, .mat-icon-button.mat-primary[disabled]:hover, .mat-icon-button.mat-warn[disabled]:hover, .mat-icon-button[disabled]:hover[disabled]:hover, .mat-stroked-button.mat-accent[disabled]:hover, .mat-stroked-button.mat-primary[disabled]:hover, .mat-stroked-button.mat-warn[disabled]:hover, .mat-stroked-button[disabled]:hover[disabled]:hover {
    box-shadow: none !important;
}

.mat-icon-button[disabled]:hover[disabled]:hover.linkInterno:hover {
    box-shadow: none !important
}

.mat-button.mat-accent[disabled] .notDisa, .mat-button.mat-primary[disabled] .notDisa, .mat-button.mat-warn[disabled] .notDisa, .mat-button[disabled][disabled] .notDisa, .mat-icon-button.mat-accent[disabled] .notDisa, .mat-icon-button.mat-primary[disabled] .notDisa, .mat-icon-button.mat-warn[disabled] .notDisa, .mat-icon-button[disabled][disabled], .mat-stroked-button.mat-accent[disabled] .notDisa, .mat-stroked-button.mat-primary[disabled] .notDisa, .mat-stroked-button.mat-warn[disabled] .notDisa, .mat-stroked-button[disabled][disabled] .notDisa {
    display: none;
}

.mat-button.mat-accent .disa, .mat-button.mat-primary .disa, .mat-button.mat-warn .disa, .mat-button .disa, .mat-icon-button.mat-accent .disa, .mat-icon-button.mat-primary .disa, .mat-icon-button.mat-warn .disa, .mat-icon-button, .mat-stroked-button.mat-accent .disa, .mat-stroked-button.mat-primary .disa, .mat-stroked-button.mat-warn .disa, .mat-stroked-button .disa {
    display: none;
}

.mat-button.mat-accent[disabled] .disa, .mat-button.mat-primary[disabled] .disa, .mat-button.mat-warn[disabled] .disa, .mat-button[disabled][disabled] .disa, .mat-icon-button.mat-accent[disabled] .disa, .mat-icon-button.mat-primary[disabled] .disa, .mat-icon-button.mat-warn[disabled] .disa, .mat-icon-button[disabled][disabled], .mat-stroked-button.mat-accent[disabled] .disa, .mat-stroked-button.mat-primary[disabled] .disa, .mat-stroked-button.mat-warn[disabled] .disa, .mat-stroked-button[disabled][disabled] .disa {
    display: block;
}

.mat-fab.mat-accent[disabled] .notDisa, .mat-fab.mat-primary[disabled] .notDisa, .mat-fab.mat-warn[disabled] .notDisa, .mat-fab[disabled] .notDisa[disabled] .notDisa, .mat-flat-button.mat-accent[disabled] .notDisa, .mat-flat-button.mat-primary[disabled] .notDisa, .mat-flat-button.mat-warn[disabled] .notDisa, .mat-flat-button[disabled] .notDisa[disabled] .notDisa, .mat-mini-fab.mat-accent[disabled] .notDisa, .mat-mini-fab.mat-primary[disabled] .notDisa, .mat-mini-fab.mat-warn[disabled] .notDisa, .mat-mini-fab[disabled] .notDisa[disabled] .notDisa, .mat-raised-button.mat-accent[disabled] .notDisa, .mat-raised-button.mat-primary[disabled] .notDisa, .mat-raised-button.mat-warn[disabled] .notDisa, .mat-raised-button[disabled] .notDisa[disabled] .notDisa {
    display: none;
}

.mat-fab.mat-accent .disa, .mat-fab.mat-primary .disa, .mat-fab.mat-warn .disa, .mat-fab .disa .disa, .mat-flat-button.mat-accent .disa, .mat-flat-button.mat-primary .disa, .mat-flat-button.mat-warn .disa, .mat-flat-button .disa .disa, .mat-mini-fab.mat-accent .disa, .mat-mini-fab.mat-primary .disa, .mat-mini-fab.mat-warn .disa, .mat-mini-fab .disa .disa, .mat-raised-button.mat-accent .disa, .mat-raised-button.mat-primary .disa, .mat-raised-button.mat-warn .disa, .mat-raised-button .disa .disa {
    display: none;
}

.mat-fab.mat-accent[disabled] .disa, .mat-fab.mat-primary[disabled] .disa, .mat-fab.mat-warn[disabled] .disa, .mat-fab[disabled] .disa[disabled] .disa, .mat-flat-button.mat-accent[disabled] .disa, .mat-flat-button.mat-primary[disabled] .disa, .mat-flat-button.mat-warn[disabled] .disa, .mat-flat-button[disabled] .disa[disabled] .disa, .mat-mini-fab.mat-accent[disabled] .disa, .mat-mini-fab.mat-primary[disabled] .disa, .mat-mini-fab.mat-warn[disabled] .disa, .mat-mini-fab[disabled] .disa[disabled] .disa, .mat-raised-button.mat-accent[disabled] .disa, .mat-raised-button.mat-primary[disabled] .disa, .mat-raised-button.mat-warn[disabled] .disa, .mat-raised-button[disabled] .disa[disabled] .disa {
    display: block;
}

.mat-fab.mat-accent[disabled]:hover, .mat-fab.mat-primary[disabled]:hover, .mat-fab.mat-warn[disabled]:hover, .mat-fab[disabled]:hover[disabled]:hover, .mat-flat-button.mat-accent[disabled]:hover, .mat-flat-button.mat-primary[disabled]:hover, .mat-flat-button.mat-warn[disabled]:hover, .mat-flat-button[disabled]:hover[disabled]:hover, .mat-mini-fab.mat-accent[disabled]:hover, .mat-mini-fab.mat-primary[disabled]:hover, .mat-mini-fab.mat-warn[disabled]:hover, .mat-mini-fab[disabled]:hover[disabled]:hover, .mat-raised-button.mat-accent[disabled]:hover, .mat-raised-button.mat-primary[disabled]:hover, .mat-raised-button.mat-warn[disabled]:hover, .mat-raised-button[disabled]:hover[disabled]:hover {
    box-shadow: 0px 0px 3px 2px rgba(0,0,0,.26) !important;
}

.mat-raised-button.mat-primary[disabled] .notDisa, .mat-raised-button.mat-warn[disabled] .notDisa, .mat-raised-button[disabled][disabled] .notDisa {
    display: none;
}

.mat-raised-button.mat-primary .disa, .mat-raised-button.mat-warn .disa, .mat-raised-button .disa {
    display: none;
}

.mat-raised-button.mat-primary[disabled] .disa, .mat-raised-button.mat-warn[disabled] .disa, .mat-raised-button[disabled][disabled] .disa {
    display: block;
}

.mat-raised-button.mat-primary[disabled], .mat-raised-button.mat-warn[disabled], .mat-raised-button[disabled][disabled] {
    color: rgba(0,0,0,.26) !important;
}

    .mat-raised-button.mat-primary[disabled]:hover, .mat-raised-button.mat-warn[disabled]:hover, .mat-raised-button[disabled][disabled]:hover {
        box-shadow: 0px 0px 3px 2px rgba(0,0,0,.26) !important;
    }

.rm-paginator-controls{
    display:flex;
}

.mat-dialog-actions p {
    font-family: Roboto,"Helvetica Neue",sans-serif;
    margin-bottom: 25px;
}

@media (max-width: 1500px) {
    .mat-calendar {
        position: fixed;
        background: white;
        border: 1px solid;
    }
    .submit_btn.mat-raised-button.fixedSaveButton {
        position: fixed;
        z-index: 999999;
        bottom: 60px;
        right: 68px;
        width: 65px;
        height: 62px;
        border-radius: 50%;
        padding: 0px;
        margin: 0px;
        min-width: 5px;
    }
    .smallScreens {
        font-size:14px!important;
    }
    .specialColLG {
        -webkit-flex: 0 0 33.333333% !important;
        flex: 0 0 33.333333% !important;
        max-width: 33.333333% !important;
    }
    .specialCol10 {
        -webkit-box-flex: 0 !important;
        -webkit-flex: 0 0 83.333333% !important;
        -ms-flex: 0 0 83.333333% !important;
        flex: 0 0 83.333333% !important;
        max-width: 83.333333% !important;
    }

    .specialCol4 {
        -webkit-box-flex: 0 !important;
        -webkit-flex: 0 0 33.333333% !important;
        -ms-flex: 0 0 33.333333% !important;
        flex: 0 0 33.333333% !important;
        max-width: 33.333333% !important;
    }
    .specialCol2 {
        -webkit-box-flex: 0 !important;
        -webkit-flex: 0 0 16.666667% !important;
        -ms-flex: 0 0 16.666667% !important;
        flex: 0 0 16.666667% !important;
        max-width: 16.666667% !important;
    }
    .side-bar-collapsed-btn[_ngcontent-c7] {
        height: 57px !important;
    }
    .containerSmallScreen {
        max-width: 1010px !important;
        margin: 20px auto !important;
    }
    .specialML{
        margin-left:40px!important;
    }
    .specialFontSize{
        font-size: 10px !important;
    }
    .specialFontSize span {
        font-size: 11px !important;
    }
}

.radioSelect .mat-radio-label-content {
    padding-right: 8px;
    padding-left: 4px
}

.aBitPadded .mat-expansion-indicator::after {
    padding: 5px;
}

.aBitPadded .mat-expansion-indicator {
    height: calc(100% + 10px)
}

.sCheck .mat-checkbox-layout {
    width: 100% !important;
}

.sCheck .mat-checkbox-inner-container {
    margin-left: 0px;
}

.sCheck .mat-checkbox-label {
    width: 100%;
}

.sCheck mat-slide-toggle {
    float: right;
}

.filter-option > .mat-pseudo-checkbox {
    display: none !important;
}

.urlCheckAlert {
    font: 500 16px/24px Roboto,"Helvetica Neue",sans-serif;
}

.urlCheckAlert p {
    margin: 5px 0
}

.toBeSaved {
    box-shadow: 0px 0px 8px 5px #ca00008c !important;
}

.linea {
    width: 100%;
    border-top: solid 1px black;
    margin: 10px 15px;
}

.w-90 .mat-form-field-wrapper {
    width: 95%;
}

/*#region Inherited asset table*/


.in-as .mat-column-url {
    min-width: 25% !important;    
}

.in-as .mat-column-publicationDate {
    max-width: 140px;
}

.in-as .mat-column-preview {
    max-width: 75px;
}

.in-as .mat-column-status {
    max-width: 75px;
}

.in-as .mat-column-evaluation {
    max-width: 75px;
}

.in-as .mat-column-evaluationIndex {
    max-width: 75px;
}

.in-as .mat-column-notes {
    max-width: 75px;
}

.in-as .mat-row .mat-cell {
    font-size: 12px!important;
}


/*#endregion Inherited asset table*/

/*#region Content table*/

.cont-asset .mat-column-url {
    max-width: 25% !important;
}

.cont-asset .mat-column-preview,
.cont-asset .mat-column-author {
    max-width: 8% !important;
}

.cont-asset .mat-column-title {
    max-width: 16% !important;
}

.cont-asset .mat-column-publicationDate {
    max-width: 8%;
}

.cont-asset .disabled {
    background-color: #b5b5b5 !important;
}

.serpDesc {
    font-weight: 100;
    margin-top: 0
}

.cont-asset .mat-badge-small .mat-badge-content {
    font-size: 12px;
    width: 12px;
    height: 12px;
    right: -5px !important;
    top: -5px !important;
}

.contentWidget span {
    height: 100%;
    display: block;
    top: 0;
    position: absolute;
    margin-left: 18px;
}

.contentWidget .col-2 {
    display: flex;
    justify-content: center;
    padding: 5px 0
}

.contentWidget .green span {
    background-color: rgb(50, 153, 50);
}

.contentWidget .red span {
    background-color: rgb(255, 50, 50);
}

/*#endregion Content table*/
/* The Modal (background) */
.modal {
    display: none;
    position: fixed;
    z-index: 1001;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: black;
}

.bookmark {
    background: #586678;
    background-image: -webkit-gradient(linear, to left top, to left bottom, color-stop(0%,#586678), color-stop(100%,#2f4057));
    background-image: -webkit-linear-gradient(to top, #586678 0%, #2f4057 100%);
    background-image: -moz-linear-gradient(to top, #586678 0%, #2f4057 100%);
    background-image: -o-linear-gradient(to top, #586678 0%, #2f4057 100%);
    background-image: -ms-linear-gradient(to top, #586678 0%, #2f4057 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#586678', endColorstr='#2f4057',GradientType=0 );
    background-image: linear-gradient(to top, #586678 0%, #2f4057 100%);
    height: 30px;
    position: absolute;
    top: 0;
    right: 20px;
    width: 20px;
    justify-content: center;
    display: flex;
}

.bookmark:after {
    content: '';
    display: block;
    border: 10px solid transparent;
    border-bottom-color: #fff;
    position: absolute;
    bottom: 0;
}

.bookmark.disabled {
    background: #d6d6d6
}

.bookmark img {
    width: 15px;
    margin-top: -10px;
}

.swiper-slide .chipsFilter .mat-select-placeholder {
    color: #fff
}

.mat-accent .mat-select-value .mat-select-placeholder {
    color: #fff;
}

.softSearch .search-input .mat-mdc-form-field-flex {
    padding: 9px 20px
}

.row.contentWidget .col {
    padding: 0
}

.contentWidget .disabled,
.contentWidget p.disabled {
    background-color: #cecece !important;
}

.mat-header-row {
    min-height: 63px
}

.smallCheckbox {
    display: block;
}

.smallCheckbox .mat-checkbox-label {
    line-height: 18px !important;
}

.smallCheckbox .mat-checkbox-inner-container {
    height: 15px;
    width: 15px;
}

.in-as mat-row:hover {
    cursor: auto
}

.in-as .mat-cell.cdk-column-preview:hover {
    cursor: pointer
}

.toCreate .mat-checkbox-background {
    background-color: #0086f9
}

.h-none #contentChartCanvas {
    min-height: 0px !important;
    height: 0px !important;
    display:  none
}

.h-none #contentChartCakeCanvas {
    min-height: 0px !important;
    height: 0px !important;
    display: none
}

#angularEditor {
    z-index: 0
}

.aired .mat-chip-list-wrapper {
    padding: 10px
}

.mat-slide-toggle.noColorChange.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
    background-color: #fafafa !important
}

.mat-slide-toggle.noColorChange.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
    background-color: rgba(0,0,0,.38) !important
}

@media (max-width: 767px) {
    .softLegalTable .mat-header-row {
        display: none
    }

    .navbar-margin {
        padding-top: 0;
    }

    .selectQuerySelect .mat-select-value-text {
        white-space: break-spaces !important
    }

    .sepSelect .mat-mdc-select-value-text {
        text-wrap: wrap;
        white-space: break-spaces;
    }
}

@media (max-width: 576px) {
    .search-input .mat-form-field-infix {
        width: 100%
    }
}

@media (min-width:767px) {
    .flexContainerSlider .swiper-container {
        display: flex
    }
}

.login-card .mat-form-field.mat-form-field-invalid .mat-form-field-label,
.login-card .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker, 
.login-card .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent {
    color: rgba(0,0,0,.54);
}

.username-button .mat-select-value-text {
    color: #f5f5f5;
    display: none;
}

@media (min-width: 1500px) {
    .swiper-wrapper {
        transform: translate3d(0px, 0px, 0px) !important;
    }
    .autoconfigDesktop .swiper-slide {
        width: auto !important
    }
    .autoconfigDesktop .swiper-button-next,
    .autoconfigDesktop .swiper-button-prev {
        display: none;
    }
}



/*panel*/

.mat-mdc-option{
    background: white;
}

    .mat-mdc-option:focus:not(.mat-mdc-option-disabled), .mat-mdc-option:hover:not(.mat-mdc-option-disabled) {
        background: #F5F5F5 !important;
    }

.PanelMyUniumX {
    position: absolute !important;
    top: 10px !important;
    right: -18px !important;
    width: 185px !important;
    border-radius: 5px !important;
}

    .PanelMyUniumX .mat-mdc-option {
        height: auto !important;
        font-size: 15px !important;
    }

.PanelMyUniumXSerp {
    position: absolute !important;
    top: 10px;
    left: 0px;
    min-width: calc(100% + 100px) !important;
    width: 400px !important;
    max-width: unset !important;
    border-radius: 5px !important;
    max-height: 350px !important;
}

@media (max-width: 768px) {
    .PanelMyUniumXSerp {
        left: -10px;
        min-width: calc(100% + 32px) !important;
    }
        .PanelMyUniumXSerp .mat-mdc-option-text {
            white-space: normal;
            line-height: 20px;
        }

        .PanelMyUniumXSerp .mat-mdc-option:nth-child(odd) {
            background: #f1f1f1 !important;
        }
}


.mat-select {
    width: auto !important;
}

.search-input .mat-mdc-form-field-flex {
    background: #F5F5F5;
    border-radius: 50px;
    padding: 0 20px;
}

.search-input-mobile .mat-form-field-flex {
    background: #F5F5F5;
    border-radius: 50px;
    padding: 10px 20px;
}

.search-input .mat-form-field-infix {
    border-top: none;
    padding: 0;
}

.search-input .mat-form-field-wrapper {
    padding-bottom: 0;
}

.search-input-mobile.whiteInput .mat-form-field-flex {
    background: #fff
}

.transitionSearch {
    animation: fadeInFromNone 0.3s ease-out;
}

@keyframes fadeInFromNone {
    0% {
        opacity: 0;
    }

    1% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.PanelOrder {
    position: absolute;
    top: 50px;
    left: -183px;
    width: 250px;
    border-radius: 5px;
}

.PanelOrderSoft {
    position: absolute !important;
    top: 10px;
    left: -54px;
    width: 15rem !important;
    border-radius: 5px !important;
}
.checkSelect {
    display: none;
}
.mat-active .checkSelect {
    display: block !important;
}

    .mat-accent .mat-select-arrow {
        color: white;
    }

.PanelMyUniumXOrdinamento {
    position: absolute !important;
    top: 10px !important;
    left: -38px !important;
    width: 140px !important;
    border-radius: 5px !important;
}

.PanelMyUniumXOrdinamentoSoft {
    position: absolute;
    top: 50px;
    left: -28px;
    width: 140px;
    border-radius: 5px;
}

.OrderPanel {
    position: absolute !important;
    top: 10px !important;
    left: -130px !important;
    width: 145px !important;
    border-radius: 5px !important;
}


.TypePanel {
    position: absolute !important;
    top: 10px !important;
    left: -135px !important;
    width: 145px !important;
    border-radius: 5px !important;
}

@media screen and (min-width: 768px) {
    .TypePanel {
        left: -102px;
        width: 192px;
    }

    .OrderPanel {
        left: -85px;
        width: 175px;
    }
}



/* end panel */
.mat-select-value {
    max-width: 100% !important;
}

.mat-button-focus-overlay {
    display: none !important;
}


.autoconfig .swiper-slide {
    width: auto !important
}

.autoconfigDesktop .swiper-button-next::after,
.autoconfigDesktop .swiper-button-prev::after {
    font-size: 24px;
}



.selectQuerySelect .mat-select-trigger {
    position: relative;
    left: 10px;
}

.mat-raised-button[disableRipple]:hover[disabled]:hover {
    box-shadow: none !important;
    cursor: default
}

.mat-raised-button[disableRipple] .cursor-pointer:hover,
.mat-raised-button[disableRipple] span:hover {
    cursor: default
}

input.mat-input-element {
    height: 100%
}

.pxBottom .mat-select-value {
    position: relative;
    top: -1px
}

.mat-select {
    height: 100%;
    align-items: center;
}

.swiper-button-next, .swiper-button-prev {
    outline: none;
    -webkit-tap-highlight-color: transparent;
}

.mat-form-field-label {
    height: 1.5em
}

.serpGenerator .mat-select-value {
    height: 20px;
}
.serpGenerator .mat-select{
    width: 100% !important;
}
.mat-autocomplete-panel {
    min-width: calc(100% + 32px) !important;
    left: -16px;
}

#slideDocument .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
    background: white !important;
}

#slideDocument .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
    background-color: rgba(0, 0, 0, 0.38) !important
}

.swiper-button-next:after, .swiper-button-prev:after{
    font-size: 20px !important;
}


/*#region ANGLUAR MATERIAL OVERRAID*/
.mat-mdc-select{
    width: auto !important;
}

.mat-mdc-option:hover:not(.mat-option-disabled) {
    background: #F5F5F5 !important;
}
.mat-mdc-raised-button.mat-primary {
    border: 1px solid var(--primary) !important;
    background-color: #fff !important;
}
.mat-mdc-raised-button.mat-accent {
    border: 1px solid var(--accent) !important;
    background-color: var(--accent) !important;
    color: white !important;
    line-height: 36px !important;
}

.mdc-text-field{
    padding: 0 !important;
}
.mat-mdc-card {
    transition: box-shadow 280ms cubic-bezier(.4,0,.2,1) !important;
    display: block !important;
    position: relative !important;
    padding: 24px !important;
    border-radius: 2px !important;
}

@media screen and (max-width: 792px) {
    .card-no-padding-mobile {
        padding: 0px !important;
    }
}

.mdc-button__label {
    display: flex;
    align-items: center;
    font-weight: 500!important;
}

.mat-mdc-select-panel {
    min-width: 200px !important;
    max-width: 280px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    max-height: 256px !important;
}

/*
.mat-mdc-form-field-subscript-wrapper {
    display: none !important;
}
*/
div.mat-mdc-select-panel{
    background: white !important;
}

.mat-mdc-progress-spinner {
    stroke: var(--primary);
}

.mat-mdc-select-value-text, .mat-mdc-select-placeholder {
    margin-right: 1rem !important;
    margin-left: 0rem !important;
}

mat-cell:first-of-type{
    padding-left: 0 !important;
}

.mat-mdc-cell {
    border: none !important;
}

.mat-mdc-dialog-surface{
    padding: 1.5rem !important
}

.mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input, .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
    opacity: 0.5;
}

.mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input:not(:placeholder-shown),
.mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control:not(:placeholder-shown) {
    color: black;
    opacity: 1;
}

.mat-mdc-raised-button:not([class*=mat-elevation-z]) {
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
}

.mat-mdc-raised-button.mat-primary {
    border: 1px solid var(--primary);
    background-color: #fff;
}

.mat-mdc-form-field-flex {
    display: inline-flex!important;
    align-items: baseline!important;
    box-sizing: border-box!important;
    width: 100%!important;
}

.mat-mdc-form-field-hide-placeholder .mat-mdc-select-placeholder {
    color: transparent;
    -webkit-text-fill-color: transparent;
    transition: none;
    display: block;
}


.mat-mdc-input-element {
    font: inherit;
    background: 0 0;
    color: currentColor;
    border: none;
    outline: 0;
    padding: 0;
    margin: 0;
    width: 100%;
    max-width: 100%;
    vertical-align: bottom;
    text-align: inherit;
}

.mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-underline {
    height: 1px!important;
}

.mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-underline {
    background-color: rgba(0, 0, 0, 0.42)!important;
}

.mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-underline {
    bottom: 1.25em;
}

.mat-mdc-form-field-underline {
    position: absolute;
    width: 100%;
    pointer-events: none;
    transform: scaleY(1.0001);
}

.cardSerpGenerator[_ngcontent-ng-c4143304417] {
    border-radius: 10px!important;
    padding: 55px 70px!important;
    box-shadow: 0 0 25px 20px rgb(0 0 0 / 3%) !important;
}
/*
.mat-mdc-select-value {
    display: table-cell; 
    max-width: 0; 
    width: 100%; 
    overflow: hidden; 
    text-overflow: ellipsis; 
    white-space: nowrap; 
}
*/
.mat-mdc-form-field-error {
    color: red!important;
    font-size: 12px;
}

.mat-mdc-icon-button svg {
    width: 1em!important;
}

.mat-mdc-expansion-panel-content {
    font: 400 14px/20px "Inter", sans-serif !important;
}

.mdc-text-field--filled .mdc-line-ripple::before {
    border-bottom-width: thin !important; 
    color: rgba(0, 0, 0, 0.42)!important;
}
    
.mat-expansion-panel-header {
    height: 64px!important;
}

.mat-mdc-row{
    position: relative!important;
    margin-bottom: 15px!important;
    border-bottom: none!important;
    padding: 11px 20px!important;
    border-radius: 15px!important;
}

.mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label, .mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label--float-above {
    color: red!important;
}

.mdc-text-field--no-label .mat-mdc-form-field-infix {
    padding-top: 0.84375em;
}

.posRel[_ngcontent-ng-c3119358142]{
    position: relative!important;
    margin-bottom: 15px!important;
    border-bottom: none!important;
    padding: 25px 20px!important;
    background-color: #f5f5f5!important;
    border-radius: 8px!important;
}

.rm-paginator[_ngcontent-ng-c110492500] {
    align-items: center!important;
}

.mat-pseudo-checkbox-checked{
background: none!important;
}

.multiple-select .mat-pseudo-checkbox-checked{
    background: var(--accent) !important;
}

.multiple-options .mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked::after{
    color: var(--accent) !important;
}

.mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after {
color: #007aff!important;
}

.cdk-overlay-container:empty {
    display: none!important;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: calc(var(--mat-form-field-container-height)/2);
}

.mdc-text-fild{
    overflow: visible!important;
}

input {
    overflow: visible!important;
}

.no-pseudo-check .mat-pseudo-checkbox{
    display: none !important;
}

#myForm1 .mdc-text-field--filled .mdc-line-ripple::before{
    border-bottom-width: 0 !important;
    color: rgba(0, 0, 0, 0.42) !important;
}

#myForm1 .mdc-text-field--filled .mdc-line-ripple::after {
    border-bottom-width: 0 !important;
    color: rgba(0, 0, 0, 0.42) !important;
}

.mdc-text-field--filled .mdc-line-ripple::after{
    border-bottom-width: 1.5px !important;
    color: black !important;
}

.mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
    border-bottom-color: red!important;
}

.softRow[_ngcontent-ng-c613356538] {
    margin-bottom: 15px!important;
    border-bottom: none!important;
    padding: 25px 0!important;
    background-color: #f5f5f5!important;
    border-radius: 8px!important;
}

.mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input, .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
    height: 100%!important; 
}

.rm-paginator-controls {
    display: flex;
    padding-bottom: 10px;
}


.mat-mdc-form-field .mat-mdc-floating-label.mdc-floating-label {
    color: rgba(0, 0, 0, 0.42)!important;
}

.mdc-text-field--filled .mdc-line-ripple::before {
    margin-bottom: 5px!important;
}

.mdc-text-field--filled .mdc-line-ripple::after {
    margin-bottom: 15px!important;
}

.mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
padding: 0 0px!important;
}

.mat-mdc-form-field-subscript-wrapper, .mat-mdc-form-field-bottom-align::before {
    margin-top: -10px!important;
}


#select .mat-mdc-select-value-text {
    display: none!important;
}


#select-pad2{
    margin-top:18px!important;
}

/*
.mat-mdc-select-placeholder {
    color: rgba(0, 0, 0, 0.42)!important;
}*/


@media screen and (max-width: 992px){
mat-cell:last-of-type, mat-header-cell:last-of-type, mat-footer-cell:last-of-type {
    padding-right: 32px!important;
}
}

@media (max-width: 767px){
.smallerFontMobile[_ngcontent-ng-c995350320] {
    display: flex;
    justify-content: center;
}
}





/*#endregion*/
