:root {
    --primary: #002761;
    --accent: #005EEB;
    --warn: #F44336;
    --grey-light: #FCFCFC;
    --grey: #eef0ef;
    --grey-500: #9E9E9E;
    --grey-dark: #757575;
    --grey-darkest: #686867;
    --blue-alt: #345f8c;
    --green: #29a856;
    --green-light: #a0eda9;
    --green-dark: #2e8936;
    --red-light: #f3b3b3;
    --pink-hot: #eb128a;
    --violet: #8b4694;
    --red-dark: #911914;
    --orange: #f18318;
    --teal: #2bb5b1;
    --indigo-100: #E8EAF6;
    --indigo-200: #2f4057;
    --indigo-300: #2f4057;
    --yellow-500: #FFC107;
    --black-700: #616161;
    --black-800: #424242;
    --green-trans: #29a8566e;
    --warn-trans: #f44336c2;
}
